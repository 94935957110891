.avatar {
	width: 100px;
	height: 100px;
}

.avatar-sm {
	width: 40px;
	height: 40px;
}

// .back-office {
//   h1,
//   .h1 {
//     @include font-size($h1-font-size);
//     margin-bottom: 0.25em;
//   }

//   .row {
//     display: flex;
//     margin: 0 -15px;
//   }

//   h2,
//   .h2 {
//     @include font-size($h2-font-size);
//     margin-bottom: 0.25em;
//   }

//   h3,
//   .h3 {
//     @include font-size($h3-font-size);
//     margin-bottom: 0.25em;
//   }

//   h4,
//   .h4 {
//     @include font-size($h4-font-size);
//     margin-bottom: 0.25em;
//   }

//   h5,
//   .h5 {
//     @include font-size($h5-font-size);
//     margin-bottom: 0.25em;
//   }

//   h6,
//   .h6 {
//     @include font-size($h6-font-size);
//     margin-bottom: 0.25em;
//   }

//   // Reset margins on paragraphs
//   //
//   // Similarly, the top margin on `<p>`s get reset. However, we also reset the
//   // bottom margin to use `rem` units instead of `em`.

//   p {
//     margin-top: 0;
//     margin-bottom: $paragraph-margin-bottom;
//   }

//   .btn {
//     @apply py-2 px-4 rounded;
//   }

//   .btn-pill {
//     @apply rounded-full;
//   }

//   .btn-primary {
//     @apply bg-blue-600 text-white;

//     &:hover {
//       @apply bg-blue-700;
//     }
//   }

//   .btn-success {
//     @apply bg-green-500 text-white;

//     &:hover {
//       @apply bg-green-700;
//     }
//   }

//   .btn-danger {
//     @apply bg-red-500 text-white;

//     &:hover {
//       @apply bg-red-700;
//     }
//   }

//   .btn-warning {
//     @apply bg-orange-500 text-white;

//     &:hover {
//       @apply bg-orange-700;
//     }
//   }

//   .btn-secondary {
//     @apply bg-gray-400 text-white;

//     &:hover {
//       @apply bg-gray-600;
//     }
//   }

//   .btn-lg {
//     @apply text-lg;
//     @apply py-3 px-6 rounded;
//   }

//   .bg-success {
//     @apply bg-green-400;
//   }

//   .bg-dark {
//     @apply bg-gray-800;
//   }

//   .bg-secondary {
//     @apply bg-gray-400;
//   }

//   .bg-light {
//     @apply bg-gray-50;
//   }

//   .bg-danger {
//     @apply bg-red-500;
//   }

//   .bg-primary {
//     @apply bg-blue-600;
//   }

//   .flex-column {
//     flex-direction: column;
//   }

//   .flex-row {
//     flex-direction: row;
//   }

//   .border-end {
//     border-right: solid 1px $border-color;
//   }

//   .border-start {
//     border-left: 1px;
//   }

//   @for $i from 0 through 5 {
//     .me-#{$i} {
//       margin-right: 1em * 5;
//     }

//     .ms-#{$i} {
//       margin-left: 1em * 5;
//     }
//   }

//   .visually-hidden {
//       display: none;
//   }

//   .btn-close {
//     color: black;
//     &:after {
//       content: "X";
//     }
//   }

//   .container {
//       @apply mx-auto;
//   }

//   .float-end {
//     @apply float-right;
//   }

//   .float-start {
//     @apply float-left;
//   }
// }

body,
html {
	@apply h-full;
	font-family: 'Rubik', Arial, Arial, Helvetica, sans-serif;
}

#__next {
	@apply h-full;
}

input[type='radio'] + label {
	@apply cursor-pointer;
}

.error-text {
	@apply flex items-center font-medium tracking-wide text-red-500 text-base mt-1 ml-1 mb-3;
}

.react-select__menu {
	z-index: 10 !important;
	cursor: pointer;
}

// Toasts!
@media screen and (min-width: 1337px) {
	.toasts {
		position: fixed;
		top: 0.5em;
		right: 2.1em;
	}
}
@media screen and (max-width: 1336px) {
	.toasts {
		position: fixed;
		bottom: 50px;
		left: calc(50% - 150px);
	}
}

.toasts {
	z-index: 99999;
}

.toast {
	width: 300px;
	z-index: 9999;
}

.bg-backoffice {
	background-color: #f9fafb;
}

.back-office {
	display: flex;
	flex-direction: column;
	background-color: #f9fafb;
	justify-content: space-between;
	min-height: 100vh;
}

.back-office > * {
	flex-grow: 1;
}
.back-office > *:first-child {
	flex-grow: 0;
}
.modal {
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	display: flex !important;
	justify-content: center;
	align-items: center;
	background-color: #333333aa;

	&-body:not(.confirm) {
		min-height: 66vh;
		@media (min-width: 768px) {
			min-height: 66vh;
		}
	}
}
.modal-dialog.back-office {
	display: block;
	min-height: max-content;
	width: 60vw;
	margin: auto;
	border-radius: 1em;
	box-shadow: 0 0 15px #00000066;
	margin-top: 3em;
	&.modal-sm {
		width: 30vw;
	}

	&.fullscreen {
		width: 95vw;
		height: 80vh;
	}
	@media (max-width: 768px) {
		&.mobile-fullscreen {
			width: 95vw;
			height: 80vh;
		}
	}
	@media (max-width: 440px) {
		&.mobile-fullscreen.embed {
			width: 95vw;
			height: 82vh;
		}
	}
}

.modal .formgroup {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;

	&.block {
		display: block;
	}
}

.modal .formgroup > label {
	align-self: center;
	display: block;
	height: 100%;
	grid-row: 1/3;
	padding: 1em;

	@media (max-width: 426px) {
		padding: 1em 0.3em;
		grid-column: 1/3;

		& + label {
			grid-column: 3/3;
			text-align: right;
		}
	}
}
.modal .formgroup > input {
	grid-column: 2/4;
	margin: 0.5em;
	@media (max-width: 426px) {
		margin: 0;
		grid-column: 1/4;
	}
}
.modal .formgroup > textarea {
	grid-column: 2/4;
	margin: 0.5em;
	min-height: 10em;
	@media (max-width: 426px) {
		grid-column: 1/4;
		margin: 0;
	}
}
.modal .feedback {
	grid-column: 2/4;
	padding-left: 0.6em;
	font-size: 0.8em;
	margin-top: -0.3em;
	@media (max-width: 426px) {
		grid-column: 1/4;
	}
}
input[type='checkbox'] {
	min-width: 1em;
	min-height: 1em;
	border: 1px solid #ccc;
	align-self: flex-start;
	margin-top: 3px;
}
.modal-body {
	overflow-y: scroll;
	max-height: calc(100vh - 20em);
}

.modal .address-form .formgroup {
	grid-template-columns: 1fr 1fr 1fr 1fr;

	.address-input {
		grid-column: 1/5;
		margin: 5px;

		& + .feedback {
			grid-column: 1/4;
		}
	}
}
input[type='file']::-webkit-file-upload-button,
input[type='file']::file-selector-button {
	@apply text-white bg-gray-700 hover:bg-gray-600 font-medium text-sm cursor-pointer border-0 py-2.5 pl-8 pr-4;
	margin-inline-start: -1rem;
	margin-inline-end: 1rem;
}

/* Demonstrate a "mostly customized" scrollbar
 * (won't be visible otherwise if width/height is specified) */
.customscroll::-webkit-scrollbar {
	width: 4px;
	height: 8px;
	background-color: #eee; /* or add it to the track */
	border-radius: 10px;
}
.customscrollwide::-webkit-scrollbar {
	width: 10px;
	height: 8px;
	background-color: #eee; /* or add it to the track */
	border-radius: 10px;
}

.hiddenscroll {
	&::-webkit-scrollbar {
		width: 0px;
		height: 0px;
		background-color: #eee; /* or add it to the track */
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background: #ccc;
		width: 0px;
		border-radius: 10px;
	}
}

/* Add a thumb */
.customscroll::-webkit-scrollbar-thumb {
	background: #ccc;
	width: 5px;
	border-radius: 10px;
}
.customscrollwide::-webkit-scrollbar-thumb {
	background: #ccc;
	width: 15px;
	border-radius: 10px;
}

input[type='file']::file-selector-button {
	background-color: #2563eb;
}
input[type='file']::file-selector-button:hover {
	background-color: #225bd6;
}

// From tailwind docs about switches;
.form-switch .form-check-input:checked {
	background-position: 100%;
	background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%223%22 fill=%22%23fff%22/%3E%3C/svg%3E');
}

.form-switch .form-check-input {
	margin-top: 0rem;
	background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%223%22 fill=%22%23fff%22/%3E%3C/svg%3E');
	background-position: 0;
	transition: background-position 0.15s ease-in-out;
}

.form-check-input:checked {
	background-color: #0d6efd;
	border-color: #0d6efd;
}

// Datepickers

@tailwind base;
@tailwind components;
@tailwind utilities;

.react-datepicker__input-container input {
	@apply block w-11/12 text-base md:text-sm bg-white border border-gray-200 rounded ml-0.5;
}

.react-datepicker-popper {
	@apply z-40 w-72 text-sm bg-white shadow px-3 py-2 border-2 border-gray-200 rounded;
}

.react-datepicker-left {
	@apply absolute left-0 right-auto top-11 transform-none;
}

.react-datepicker-right {
	@apply absolute right-0 left-auto top-11 transform-none;
}

.react-datepicker__portal {
	@apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded;
}

.react-datepicker__month-container {
	@apply flex flex-col;
}

.react-datepicker__month {
	@apply flex flex-col;
}

.react-datepicker__current-month {
	@apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
	@apply flex justify-around;
}

.react-datepicker__day-names {
	@apply flex justify-around text-gray-400 font-medium text-center text-xs;
}

.react-datepicker__day-name {
	@apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
	@apply absolute top-2;
}

.react-datepicker__navigation--previous {
	@apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__navigation--next {
	@apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__day {
	@apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded cursor-pointer;
}

.react-datepicker__day--disabled {
	@apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
	@apply text-gray-300;
}

.react-datepicker__day--in-range {
	@apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
	@apply bg-blue-200;
}

.react-datepicker__day--selecting-range-start {
	@apply bg-white border-2 border-blue-600;
}

.react-datepicker__day--selecting-range-end {
	@apply bg-white border-2 border-blue-600;
}

.react-datepicker__day--selected {
	@apply bg-blue-600 text-white;
}

.react-datepicker__day--range-start {
	@apply bg-blue-600 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
	@apply bg-blue-600 text-white hover:text-gray-700 hover:bg-white;
}

.text-xxs {
	font-size: 0.65rem;
}

.avatar-preview {
	width: 196px;
	height: 196px;
}

@import 'react-upload-gallery/dist/style.scss';

.rug .rug-items.__card {
	@media (min-width: 768px) {
		margin: 0 -30px;
	}
	@media (min-width: 1024px) {
		margin: 0 -20px;
	}
	@media (min-width: 1200px) {
		margin: 0 -10px;
	}
}

.rug-card-image {
	background-size: cover !important;
}

@media (max-width: 425px) {
	.rug {
		&-items {
			&.__card.__sorting {
				grid-template-columns: repeat(4, minmax(0, 1fr));
				@media (max-width: 375px) {
					grid-template-columns: repeat(3, minmax(0, 1fr));
				}
			}
		}
		&-item {
			width: fit-content;
		}
		&-card {
			width: 80px;
			height: 80px;
		}
	}
}

@import '~react-image-gallery/styles/scss/image-gallery.scss';

.image-gallery-thumbnail {
	width: 128px;
}

.react-select__control {
	border-color: #e5e7eb !important;

	&--is-focused {
		box-shadow: 0 0 0 1px #2563eb !important;
		border-color: #2563eb !important;
	}
}

@import './typography';

.swap-menu {
	right: 0;
	@media (max-width: 370px) {
		right: -1.5rem;
	}
}

input[type='radio']:not(:checked) + label {
	.peer-unchecked-hidden {
		display: none;
	}
}

.text-clamp {
	display: -webkit-inline-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: auto;
}

.w-38 {
	width: 9.5rem;
}

.yada {
	box-sizing: border-box;
}
